// translations.js
export const translations = {
    en: {
      home: "Home",
      aboutme: "💬 About Me",
      skill: "👨🏻‍💻 Skills",
      blog: "📝 Blogs",
      connect: "🙌🏻 Let's Connect ",
      connect2: "📱 Scan this QR code to ask me a question. ",
      photo: "📸 Photography ",
      name: "Ruikang Guo",
      greeting: "Hello dwellers of the digital realm!",
      id1: "code wizard",
      id2: "photo whisperer",
      location: "Queens, NY",
      resume: "My resume",
      aboutmedesc: "I have a Master's in Computer Science from Syracuse University. Proficient in full-stack and mobile development (Kotlin, React Native), cloud computing (AWS, Heroku), and AI tools (LangChain, Pinecone DB). Led a GenAI chatbot project at Day & Nite All Service. Ideal for full-stack software engineering and generative AI roles.",
      skilldesc: "I am always eager to learn and develop new skills.",
      blogdesc: "I always keep track of my learning process. My blogs show where I came from and where I am headed to next.",
      endaction: "Liked my page? Help me improve it.",
      callme: "I am interested in full-time job opportunities. Contact me via phone: ",
      emailme: " or email:",
      socialme: ". Connect with me on ",
      frontend: "Front-End Development",
      backend: "Back-End Development",
      database: "Database",
      vector: "Vector",
      mobile: "Mobile Development",
      genai: "GenAI",
      deployment: "Deployment Solution",
      frontenddesc: "I mostly use React for front-end development. JSX is my primary template language. I am also familiar with Bootstrap, Ant Design, and Less CSS.",
      backenddesc: "Java and Python are my primary programming languages. For backend, my technology stack includes SpringBoot and FastAPI. I have also deployed multiple projects using AWS Lambda, AWS LightSail, Heroku and Docker.",
      mobiledesc: "I really enjoyed my past experience building an Android App. Kotlin was my language of choice.",
      genaidesc: "AI is bound to change the world as we know it. As a futurist, I am thrilled to work on some exciting projects using the latest technologies.",
      professionalexperience: "Professional Experiences",
      education: "Education",
      career1: "Software Engineer - New Hyde Park, NY - Day & Nite All Service",
      career2: "Full Stack Developer Internship - Remote, US - Soca Networks",
      career1time: " July 2023 - Present",
      career2time: " May 2022 - Sep 2022",
      edu1: "M.S. In Computer Science",
      edu1location: "Syracuse University, Syracuse, NY",
      edu1time: " May 2023",
      edu2: "B.A. Geography: Data Science with Minor In Informatics",
      edu2location: "University of Washington, Seattle, WA",
      edu2time: " Jun 2021",
      edu3: "Database Management Certificate",
      edu3time: " Aug 2018",
      frameworkandpackages: "Frameworks and packages",
      language: "Programming languages",
      cloud: "Cloud platforms",
      updatedtime: "Updated: Nov 21th, 2024",
      stacks: "Tech Stacks",
      learnmore: "Learn more",
      project1: "Wavelength Android App",
      project1topic: 'Kotlin + Spring Boot + MongoDB + Digital Ocean + Firebase Authentication',
      project2: "Online Student Management System",
      project3: "Exercise Tracker Web App",
      project4: "Chatbot for personal website (version 1, deprecated)",
      project4topic: 'GenAI + Cloud Computing',
      project5: "GuoGenius (Chatbot for personal website - version 2, current)",
      projectoverview: "Project Overview",
      project5text1: "Full stack application developed using LangChain LLM framework + Pinecone vector DB + Streamlit frontend framework, deployed to the cloud",
      project5text2: "Improvements over version 1",
      project5text3: "The main difference is the usage of AI agent. The first implementation uses LlamaIndex's document retrieval RAG pipeline to get relevant information from a uploaded file. But the current implementation uses LangChain agent + tools RAG pipeline to formulate an answer.",
      project5text4: "The first implementation of chatbot is only capable of answer resume related questions. In the current version of the chatbot, in addition to answering resume questions, the agent can search the web for additional information using DuckDuckGo Search or Wikipedia. It can also calculate Math questions using a calculator tool.",
      project5text5: "Everything is packed into a Streamlit app, deployed to Heroku and embeded as an \"iframe\" element to my website. The downside is that the webpage now loads much slower due to server-side performance issues. Because Heroku automatically stops infrequently used apps, like this one, to save resources, I have noticed my Streamlit app being extra slow when it comes to booting up. Improving the chatbot loading speed (while avoiding paying more) is the next item in my development plan.",
      demovideo: "Demo Video",
      screenshot: "Screenshots",
      project4text1: "Full stack application developed using generative AI and backend engineering, deployed to the cloud",
      project4text2: "Please note the current implementation of Chatbot utilizes a different approach. This version of implementation is deprecated.",
      feature: "Features Implemented", 
      futurefeature: "Potential Future Features",
      project1text1: "Full-stack development project. Android Application + Spring Boot.",
      project2text1: "Online student management system. Developed with SpringBoot. Deployed using Docker.",
      project3text1: "MERN-stack development project.",
      gallery: "Photo Gallery",
      gallerytext1: "Engineer by profession, artist by heart.",
      gallerytext2: "Rage, rage against the dying of the light. - Dylan Thomas",
      gallerytext3: "Captivating Lights",
      gallerytext4: "I specialize in capturing urban landscapes, particularly entrancing cityscapes bathed in the hues of captivating sunsets. My deep fascination lies in the intricate dance of tonal nuances and delicate micro-contrasts.",
      gallerytext5: "Here is a collection of my favorites over the years. (Preview only, commerical use is prohibited.)",
      rkguo: "Ruikang Guo",

    },
    zh: {
      home: "主页",
      aboutme: "💬 关于我",
      skill: "👨🏻‍💻 专业技能",
      blog: "📝 博客",
      connect: "🙌🏻 联系我 ",
      connect2: "📱 扫描这个二维码向我提问。",
      photo: "📸 个人摄影集 ",
      name: "郭 睿康",
      greeting: "\"我倒，你也网上冲浪啊🏄\"",
      id1: "键盘侠（正义版）",
      id2: "大光圈爱好者",
      location: "纽约皇后区",
      resume: "个人简历",
      aboutmedesc: "我拥有雪城大学计算机科学硕士学位，精通全栈和移动开发（Kotlin、React Native）、云计算平台（AWS、Heroku）以及生成式AI工具（LangChain、Pinecone DB）。曾在一家美国公司领导过一个生成式AI聊天机器人项目。非常适合全栈软件工程和生成式AI相关职位。",
      skilldesc: "永远保持着对世界的好奇和无限的求知欲",
      blogdesc: "我用博客记录我的学习过程，希望他能告诉你我的起点和下一步的方向",
      endaction: "喜欢我的主页吗？访问源代码",
      callme: "正在寻找全职工作机会，需要联系我请拨打电话：",
      emailme: " 或者发送电子邮件至:",
      socialme: ". 还可以在以下社交平台上找到我：",
      frontend: "前端开发",
      backend: "后端开发",
      database: "数据库",
      vector: "向量",
      mobile: "移动端开发",
      genai: "生成式AI",
      deployment: "部署方案",
      frontenddesc: "前端最熟练的技术栈是ReactJS。我也能熟练运用Bootstrap, Ant Design, 和LessCSS。",
      backenddesc: "Java和Python是我最熟练的后端编程语言。后端的技术栈包括SpringBoot和FastAPI。曾经在AWS Lambda, AWS LightSail, Heroku和Docker上部署了多个后端应用程序。",
      mobiledesc: "我非常喜欢以前开发安卓应用的经历。Kotlin是我首选的移动端开发语言。",
      genaidesc: "AI一定会深刻地改变我们的世界。十分荣幸有机会在一些非常有趣的GenAI项目中贡献自己的力量。",
      professionalexperience: "工作经历",
      education: "教育经历",
      career1: "软件工程师 - 美国 纽约 长岛 - Day & Nite All Service",
      career2: "全栈开发实习生 - 美国 远程 - Soca Networks",
      career1time: " 2023年7月 - 至今",
      career2time: " 2022年5月 - 2022年9月",
      edu1: "计算机科学 硕士",
      edu1location: "美国 纽约州 雪城 雪城大学",
      edu1time: " 2023年5月",
      edu2: "地理信息学 学士 辅修信息学",
      edu2location: "美国 华盛顿州 西雅图 华盛顿大学",
      edu2time: " 2021年6月",
      edu3: "数据库管理证书",
      edu3time: " 2018年8月",
      frameworkandpackages: "开发框架和依赖包",
      language: "编程语言",
      cloud: "云计算平台",
      updatedtime: "上次更新时间: 2024年11月21号",
      stacks: "编程技术栈",
      learnmore: "了解更多",
      project1: "Wavelength 安卓应用",
      project1topic: 'Kotlin + Spring Boot + MongoDB + Digital Ocean + Firebase 授权登录',
      project2: "在线学生管理系统",
      project3: "锻炼记录网页应用",
      project4: "个人网站聊天机器人 (1.0版本 已弃用)",
      project4topic: "生成式AI + 云计算",
      project5: "GuoGenius (个人网站聊天机器人 - 2.0版 当前版本)",
      projectoverview: "项目概览",
      project5text1: "使用 LangChain 大语言模型开发框架 + Pinecone 向量数据库 + Streamlit 前端框架开发的全栈生成式AI应用程序，已部署到云端。",
      project5text2: "相比于1.0的升级",
      project5text3: "主要的区别在于 AI Agent 的使用。 第一个版本使用了 LlamaIndex 的文档检索来实现检索增强生成（RAG）工作流，从上传的文件中获取相关信息。 但当前的实现版本则采用了 LangChain 的 Agent + 工具的 RAG 工作流，依赖大语言模型智能地选择合适的工具。",
      project5text4: "第一个版本的聊天机器人仅能回答与简历相关的问题。 而在当前版本中，除了回答简历问题外，Agent 还能通过 DuckDuckGo 搜索或 Wikipedia 获取额外的信息。 它还可以通过计算器工具解决数学问题。",
      project5text5: "所有功能都打包在一个 Streamlit 应用中，部署在 Heroku 上，并以 \"iframe\" 嵌入到我的网站中。 缺点是，由于服务器性能问题（主要是没有租用很好的服务器），网页加载速度与1.0版本比明显变慢。 我注意到由于 Heroku 会自动暂停不常使用的服务（就像这个聊天机器人）以节省资源，导致我的 Streamlit 应用在启动时尤其慢。 改进聊天机器人的加载速度（并尽可能避免额外付费）是我开发计划中的下一项任务。",
      demovideo: "演示视频",
      screenshot: "截图",
      project4text1: "使用生成式人工智能和后端工程开发的全栈应用程序，已部署到云端。",
      project4text2: "请注意，当前的聊天机器人实现采用了不同的方法。此版本的实现已被弃用。",
      feature: "已实现的功能",
      futurefeature: "以后可能实现的功能",
      project1text1: "全栈开发项目。安卓应用程序 + Spring Boot。",
      project2text1: "在线学生管理系统。使用 SpringBoot 开发，并通过 Docker 部署。",
      project3text1: "MERN 栈开发项目",
      gallery: "摄影作品集",
      gallerytext1: "白天搞工程，晚上玩艺术。",
      gallerytext2: "怒斥吧，怒斥光明的消逝。— 狄兰·托马斯",
      gallerytext3: "流转的光影",
      gallerytext4: "我擅长捕捉城市风光，尤其是那些沐浴在迷人日落余晖中的景象。我痴迷于色彩层次间的微妙变化，以及光影细腻的对比之美。",
      gallerytext5: "这些是我这些年来最喜欢的作品。（仅供预览，禁止商业用途。）",
      rkguo: "郭 睿康",

    },
  };